<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :tabs="tabs" title="Discover" subtitle="Find opponents and events that match your particular interests.">

	</app-content-head>

	<app-content-body :is-call="true" v-if="!collection.length">

		<app-content-call v-if="is.failed" icon="closed" title="Matchmaker closed" :text="$settings.schedule.events.submit.content" />

		<app-content-call v-if="!is.failed && !hasInterests" icon="matchmaking" title="What games do you want to play?" text="Add your interests, as general or specific as you want, so we can tailor your matches." button="Add interests" v-on:click="onAddClick" />

		<app-content-call v-if="!is.failed && !collection.length && hasInterests" icon="interests" title="No matches currently" text="Matches are attendees that are interested in playing one or more of the same games as you. You could try adding more interests or broadening existing interests to generate more matches." button="My interests" v-on:click="onInterestsClick" />

	</app-content-body>

	<app-content-body :is-grid="2" v-if="collection.length">

		<com-match v-for="item in collection" :key="item.id" :match="item" />
		
	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	components: {
		'com-match': () => import('./conventionschedulediscovermatchmaker/Match')
	},

	data: function() {

		return {
			endpoint: 'convention/schedule/discover/matchmaker',
			live: 'convention/schedule/discover/matchmaker',
			layout: 'auto 100px 100px 100px'
		}

	},

	computed: {

		hasInterests: function() {

			return this.$store.getters['session/count/schedule/interests']

		},

		tabs: function() {

			return [
				{ name: 'Convention.Schedule.Discover', text: 'Suggestions' },
				{ name: 'Convention.Schedule.Discover.Matchmaker', text: 'Matchmaker' },
				{ name: 'Convention.Schedule.Discover.Proposals', text: 'Proposals' },
				{ name: 'Convention.Schedule.Discover.Interests', text: 'Interests' }
			]

		}

	},

	methods: {

		onInterestsClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Discover.Interests'
			})

		},

		onAddClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Discover.Interest',
				params: {
					id: 'new'
				}
			})

		}

	}

}

</script>

<style scoped>

</style>